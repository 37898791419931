import { IGatsbyImageData } from 'gatsby-plugin-image';

type ProductsType = {
    [index: string]: {
        name: string;
        price: string;
        link: string;
        image?: IGatsbyImageData;
    }[];
};

export const shopProducts: ProductsType = {
    cleansers: [
        {
            name: 'Purifying Gel Cleanser',
            price: '$45.00',
            link: 'https://www.alumiermd.com/products/purifying-gel-cleanser?code=LNUBZU3H',
        },
        {
            name: 'HydraBoost',
            price: '$46.00',
            link: 'https://www.alumiermd.com/products/hydraboost?code=LNUBZU3H',
        },
        {
            name: 'Acne Clarifying Cleanser',
            price: '$50.00',
            link: 'https://www.alumiermd.com/products/acne-clarifying-cleanser?code=LNUBZU3H  ',
        },
        {
            name: 'SensiCalm',
            price: '$46.00',
            link: 'https://www.alumiermd.com/products/sensicalm?code=LNUBZU3H',
        },
    ],
    exfoliation: [
        {
            name: 'MicroDerm Polish',
            price: '$68.00',
            link: 'https://www.alumiermd.com/products/microderm-polish?code=LNUBZU3H',
        },
        {
            name: 'Enzymatic Peel',
            price: '$86.00',
            link: 'https://www.alumiermd.com/products/enzymatic-peel?code=LNUBZU3H',
        },
        {
            name: 'AHA Renewal Serum',
            price: '$80.00',
            link: 'https://www.alumiermd.com/products/aha-renewal-serum?code=LNUBZU3H',
        },
        {
            name: 'Bright & Clear Solution',
            price: '$46.00',
            link: 'https://www.alumiermd.com/products/bright-and-clear-solution?code=LNUBZU3H',
        },
    ],
    specialty_eye: [
        {
            name: 'AluminEye™',
            price: '$116.00',
            link: 'https://www.alumiermd.com/products/alumineye?code=LNUBZU3H  ',
        },
        {
            name: 'Retinol Eye Gel',
            price: '$99.00',
            link: 'https://www.alumiermd.com/products/retinol-eye-gel?code=LNUBZU3H',
        },
        {
            name: 'Eye Rescue Pads',
            price: '$49.00',
            link: 'https://www.alumiermd.com/products/eye-rescue-pads?code=LNUBZU3H',
        },
    ],
    moisturizers: [
        {
            name: 'HydraLight',
            price: 'Check the product page',
            link: 'https://www.alumiermd.com/products/hydralight?code=LNUBZU3H',
        },
        {
            name: 'HydraDew',
            price: '$77.00',
            link: 'https://www.alumiermd.com/products/hydradew?code=LNUBZU3H',
        },
        {
            name: 'HydraRich',
            price: 'Check the product page',
            link: 'https://www.alumiermd.com/products/hydrarich?code=LNUBZU3H',
        },
        {
            name: 'HydraSmooth™',
            price: '$77.00',
            link: 'https://www.alumiermd.com/products/hydrasmooth?code=LNUBZU3H ',
        },
        {
            name: 'HydraClarité',
            price: 'Check the product page',
            link: 'https://www.alumiermd.com/products/hydraclarite?code=LNUBZU3H ',
        },
        {
            name: 'HydraCalm',
            price: '$77.00',
            link: 'https://www.alumiermd.com/products/hydracalm?code=LNUBZU3H',
        },
        {
            name: 'Recovery Balm',
            price: '$101.00',
            link: 'https://www.alumiermd.com/products/recovery-balm?code=LNUBZU3H',
        },
        {
            name: 'Neck & Décolleté Firming Cream',
            price: '$99.00',
            link: 'https://www.alumiermd.com/products/neck-and-decollete-firming-cream?code=LNUBZU3H  ',
        },
    ],
    masks: [
        {
            name: 'Aqua Infusion Mask',
            price: '$72.00',
            link: 'https://www.alumiermd.com/products/aqua-infusion-mask?code=LNUBZU3H',
        },
    ],
    treatment_serums: [
        {
            name: 'EverActive C&E™ + Peptide',
            price: '$195.00',
            link: 'https://www.alumiermd.com/products/everactive-c-e?code=LNUBZU3H',
        },
        {
            name: 'Alumience A.G.E.™',
            price: '$158.00',
            link: 'https://www.alumiermd.com/products/alumience-a-g-e?code=LNUBZU3H',
        },
        {
            name: 'Ultimate Boost Serum',
            price: '$105.00',
            link: 'https://www.alumiermd.com/products/ultimate-boost-serum?code=LNUBZU3H',
        },
        {
            name: 'Retinol Resurfacing Serum 0.25',
            price: '$82.00',
            link: 'https://www.alumiermd.com/products/retinol-resurfacing-serum-0-25?code=LNUBZU3H',
        },
        {
            name: 'Retinol Resurfacing Serum 0.5',
            price: '$94.00',
            link: 'https://www.alumiermd.com/products/retinol-resurfacing-serum-0-5?code=LNUBZU3H',
        },
        {
            name: 'Retinol Resurfacing Serum 1.0',
            price: '$104.00',
            link: 'https://www.alumiermd.com/products/retinol-resurfacing-serum-1-0?code=LNUBZU3H',
        },
        {
            name: 'Calm-R™',
            price: '$125.00',
            link: 'https://www.alumiermd.com/products/calm-r?code=LNUBZU3H',
        },
        {
            name: 'Vitamin Rich Smoother',
            price: '$120.00',
            link: 'https://www.alumiermd.com/products/vitamin-rich-smoother?code=LNUBZU3H',
        },
        {
            name: 'EvenTone',
            price: '$116.00',
            link: 'https://www.alumiermd.com/products/eventone?code=LNUBZU3H',
        },
        {
            name: 'Intellibright® Complex',
            price: '$109.00',
            link: 'https://www.alumiermd.com/products/intellibright-complex?code=LNUBZU3H',
        },
        {
            name: 'Acne Balancing Serum',
            price: '$79.00',
            link: 'https://www.alumiermd.com/products/acne-balancing-serum?code=LNUBZU3H',
        },
    ],
    sun_protection: [
        {
            name: 'Moisture Matte Broad Spectrum SPF 40 (Ivory)',
            price: '$52.00',
            link: 'https://www.alumiermd.com/products/moisture-matte-broad-spectrum-sunscreen-spf-40-ivory?code=LNUBZU3H',
        },
        {
            name: 'Moisture Matte Broad Spectrum SPF 40 (Sand)',
            price: '$52.00',
            link: 'https://www.alumiermd.com/products/moisture-matte-broad-spectrum-sunscreen-spf-40-sand?code=LNUBZU3H',
        },
        {
            name: 'Moisture Matte Broad Spectrum SPF 40 (Amber)',
            price: '$52.00',
            link: 'https://www.alumiermd.com/products/moisture-matte-broad-spectrum-sunscreen-spf-40-amber?code=LNUBZU3H',
        },
        {
            name: 'Clear Shield Broad Spectrum SPF 42',
            price: '$53.00',
            link: 'https://www.alumiermd.com/products/clear-shield-broad-spectrum-sunscreen-spf-42?code=LNUBZU3H',
        },
        {
            name: 'Sheer Hydration Broad Spectrum SPF 40 (Untinted)',
            price: '$53.00',
            link: 'https://www.alumiermd.com/products/sheer-hydration-broad-spectrum-sunscreen-spf-40?code=LNUBZU3H',
        },
        {
            name: 'Sheer Hydration Broad Spectrum SPF 40 (Versatile Tint)',
            price: '$53.00',
            link: 'https://www.alumiermd.com/products/sheer-hydration-broad-spectrum-suncreen-spf-40-versatile-tint?code=LNUBZU3H',
        },
    ],
    skincare_regimen_collections: [
        {
            name: 'Prep & Enhance Rejuvenation',
            price: '$289.00',
            link: 'https://www.alumiermd.com/products/prep-and-enhance-rejuvenation?code=LNUBZU3H',
        },
        {
            name: 'Prep & Enhance Discoloration (Non-HQ)',
            price: '$289.00',
            link: 'https://www.alumiermd.com/products/prep-and-enhance-discoloration?code=LNUBZU3H',
        },
        {
            name: 'Brightening Collection for Discoloration Non-HQ - Dry/Sensitive',
            price: '$340.00',
            link: 'https://www.alumiermd.com/products/brightening-collection-for-discoloration-dry-sensitive?code=LNUBZU3H',
        },
        {
            name: 'Rejuvenating Skin Collection - Normal/Dry',
            price: '$398.00',
            link: 'https://www.alumiermd.com/products/rejuvenating-skin-collection-normal-dry?code=LNUBZU3H',
        },
        {
            name: 'Rejuvenating Skin Collection - Normal/Oily',
            price: '$398.00',
            link: 'https://www.alumiermd.com/products/rejuvenating-skin-collection-oily?code=LNUBZU3H',
        },
        {
            name: 'Calming Collection',
            price: '$278.00',
            link: 'https://www.alumiermd.com/products/calming-collection?code=LNUBZU3H',
        },
        {
            name: 'Clarifying Collection',
            price: '$190.00',
            link: 'https://www.alumiermd.com/products/clarifying-collection?code=LNUBZU3H',
        },
    ],
};

type DivineLineType = {
    name: string;
    price: string;
    size: string;
    description: string;
    benefits: string[];
    ingredients: string[];
    image?: IGatsbyImageData;
    link: string;
}[];

export const divineLineProducts: DivineLineType = [
    {
        name: 'Divine Blossom HA Hydration Drops',
        price: '$108',
        size: '30ml',
        description:
            'This pure, nourishing serum formulated with fractionated hyaluronic acid with copper peptide penetrates deep within the dermis to draw moisture in and hold it there. A universal booster in any regimen, this serum provides all-day moisture retention and skin barrier repair. With each drop, skin appears brighter, firmer and rejuvenated.',
        benefits: [
            'Various molecular forms of hyaluronic acid (fractionated & high molecular weight) ensure continuous & optimal hydration ',
            'Powerful humectants Sodium PCA and Glycerin bind moisture and help maintain the skin barrier ',
            'Palmitoyl Tripeptide-28 supports healthy collagen production ',
            'Essential Mineral Complex (Zinc PCA and Copper PCA) conditions skin and helps to reduce excess oil.',
        ],
        link: 'https://divinebeautyformula.myshopify.com/collections/all/products/divine-blossom-ha-hydration-drops',
        ingredients: [
            'Sodium Hyaluronate',
            'Hydrolyzed Sodium Hyaluronate',
            'Sodium PCA',
            'Glycerin',
            'Palmitoyl Tripeptide-28',
            'Camellia Sinensis (white tea) Extract',
            'Ergothioneine, Glutathione',
            'Zinc PCA',
            'Copper PCA',
        ],
    },
    {
        name: 'Divine Clarity Tone Perfector Serum',
        price: '$97',
        size: '30ml',
        description:
            'Highly concentrated niacinamide serum reduces the look of discoloration, dark spots and redness—without irritation.Formulated with peptides, algae extract and antioxidants to visibly tighten and reduce fine lines, wrinkles and pore size.',
        benefits: [
            'High concentration of niacinamide in a proprietary delivery system for time-released, enhanced penetration without irritation',
            'Gently exfoliates, helping to increase cellular turnover to reduce visible discoloration',
            'Comforts and soothes irritated skin, helping to reduce the appearance of redness',
            'Renews and restores the skin barrier helping to prevent moisture loss',
            'Reduces visible pore size and helps control excess sebum',
            'Promotes a brighter appearance',
        ],
        link: 'https://divinebeautyformula.myshopify.com/collections/all/products/divine-clarity-tone-perfector-serum',
        ingredients: [
            '15% Niacinamide',
            'Algae Extract',
            'Green Tea Polyphenols & Licorice Root Extract',
            'Alpha Arbutin & Ascorbyl Glucoside',
            'Allantoin',
            'Peptide Complex',
        ],
    },
    {
        name: 'Divine Feminine Neck Firming Peptide',
        price: '$117',
        size: '50g',
        description:
            'Developed specifically for the neck and décolleté, this formula helps to firm, lift and tighten skin that has lost definition with age. Formulated with a proprietary blend of medical-grade, active ingredients to support healthy collagen and elastin production. Visibly smooths fine lines, wrinkles and crepey skin.',
        benefits: [
            'Helps firm. tighten and address the appearance of sagging skin',
            'Supports healthy collagen production',
            'Brightens skin for even tone and texture',
        ],
        link: 'https://divinebeautyformula.myshopify.com/collections/all/products/divine-feminine-neck-firming-peptide',
        ingredients: [
            'Biomimetic Ceramide Complex',
            'Peptides',
            'Niacinamide',
            'Green Tea Polyphenols',
            'Leontopodium Alpinum Callus Culture',
            'Apple stem cells',
            'LYCD',
            'Botanical Extracts',
        ],
    },
    {
        name: 'Divine Glow Collagen Boost C+E Antioxidant',
        price: '$152',
        size: '30ml',
        description:
            'High-potency vitamin C serum designed to improve the look of wrinkles, brighten skin’s complexion and support the microbiome. A protective antioxidant system guards against free radical skin damage.',
        benefits: [
            'Visibly improves fine lines, wrinkles and loss of volume',
            'Brightens the complexion, helps fade discoloration and improves overall radiance',
            'Water-based texture works for all skin types, including normal-oily',
        ],
        link: 'https://divinebeautyformula.myshopify.com/collections/all/products/divine-glow-collagen-boost-c-e-antioxidant',
        ingredients: [
            'Vitamin C (Ascorbic Acid)',
            'Tetrahexyldecyl Ascorbate',
            'Green Tea Polyphenols',
            'Ergothioneine',
            'Apigenin',
            'Vitamin E',
        ],
    },
    {
        name: 'Divine Kiss of Beauty Lip Plumping + Glossing',
        price: '$48',
        size: '3.9ml',
        description:
            'This multi-function lip treatment protects lips while helping to restore hydration and youthful volume. A proprietary complex of stimulating ingredients draw beautiful, natural color. Immediately lips appear more plump and rosy, with lasting hydration and overall health.',
        benefits: [
            'Multiple weights of hyaluronic acid draw and seal in moisture',
            'Palmitoyl Tripeptide-38 supports healthy collagen production',
        ],
        link: 'https://divinebeautyformula.myshopify.com/collections/all/products/divine-kiss-of-beauty-lip-plumping-glossing',
        ingredients: [
            'Homosalate 15.0%',
            'Octinoxate 7.5%',
            'Octisalate 5.0%',
            'Avobenzone 3.0%',
            'Hyaluronic Acid',
        ],
    },
    {
        name: 'Divine Light Bright + Tight Eye Cream',
        price: '$121',
        size: '15ml',
        description:
            'Formulated with All-Trans-Retinol: Optimally dosed for the delicate eye area, helping to increase visible firmness and elasticity and 90% Green Tea Polyphenols, this ultra-moisturizing cream targets fine lines, dark circles and puffiness to achieve smoother, firmer skin around the delicate eye area.',
        benefits: [
            'Deeply hydrating, plumping lines and wrinkles',
            'Visibly tightens skin, reducing the look of undereye bags',
            'Helps reduce the appearance of dark circles and puffiness',
        ],
        link: 'https://divinebeautyformula.myshopify.com/collections/all/products/divine-light-bright-tight-eye-cream',
        ingredients: [
            'All-trans-Retinol',
            'Niacinamide',
            'Fractionated Hyaluronic Acid',
            'Caffeine USP',
            'Ceramide NG',
            'Green Tea Polyphenols',
            'Ascorbic Acid',
            'Arnica',
            'Vitamin K',
        ],
    },
    {
        name: 'Divine Night Overnight Renewal Retinol Serum 10X',
        price: '$134',
        size: '30ml',
        description:
            'New, cutting-edge Retinoin® Active Pro-Penetrant delivery system activates a heightened level of retinization to the skin for faster skin appearance benefits even better results. Fast absorbing and cosmetically elegant formula provides the perfect dose of actives and skin improving botanicals. o help achieve a radiant skin complexion.',
        benefits: [
            'Deeply hydrating, plumping lines and wrinkles',
            'Visibly tightens skin, reducing the look of undereye bags',
            'Helps reduce the appearance of dark circles and puffiness',
        ],
        link: 'https://divinebeautyformula.myshopify.com/collections/all/products/divine-night-overnight-renewal-retinol',
        ingredients: [
            'All-trans-Retinol',
            'Bakuchiol',
            'Licorice Root',
            'Green Tea Polyphenols',
            'Caffeine',
        ],
    },
    {
        name: 'Divine Night Retinol PM Serum 5X',
        price: '$134',
        size: '30ml',
        description:
            'New, cutting-edge Retinoin® Active Pro-Penetrant delivery system activates a heightened level of retinization to the skin for faster skin appearance benefits even better results. Fast absorbing and cosmetically elegant formula provides the perfect dose of actives and skin improving botanicals. o help achieve a radiant skin complexion.',
        benefits: [
            'Reduces the look of fine lines, wrinkles, discoloration and uneven skin tone',
            'Improves the look of skin blemishes caused by acne',
            'Improves global skin clarity and radiance',
            'Protects skin from damaging free radicals',
            'Retinizes the skin without redness or irritation',
        ],
        link: 'https://divinebeautyformula.myshopify.com/collections/all/products/divine-night-retinol-pm-serum-5x',
        ingredients: [
            'All-trans-Retinol',
            'Bakuchiol',
            'Licorice Root',
            'Green Tea Polyphenols',
            'Caffeine',
        ],
    },
    {
        name: 'Divine Purity AHA/BHA Polishing Cleanser',
        price: '$62',
        size: '200ml',
        description:
            'This mild, brightening scrub with bio-friendly exfoliating beads gently cleanses by removing dry, dead skin cells and excess oils to reveal healthier, younger looking skin.',
        benefits: [
            'Glycolic Acid and Salicylic Acid USP gently slough off dull, damaged skin cells to improve skin radiance and minimize pores',
            'Enriched with Arbutin, a natural skin brightener',
            'Powerful antioxidants calm and soothe skin',
        ],
        link: 'https://divinebeautyformula.myshopify.com/collections/all/products/divine-purity-aha-bha-polishing-cleanser',
        ingredients: [
            '10% Glycolic Acid',
            '0.5% Salicylic Acid USP',
            'Arbutin',
            'Bisabolol',
            'Vitamin A-C-E',
            'Green Tea Extract',
            'Co-Q10',
        ],
    },
    {
        name: 'Divine Radiance Lactic Acid Resurfacing',
        price: '$97',
        size: '30ml',
        description:
            'This gentle exfoliator contains Lactic Acid to smooth skin texture as it improves hydration—without irritation. This alternative to Glycolic Acid is specially formulated for rosacea and sensitive skin types effective for all skin concerns (acne-prone, aging, discoloration).',
        benefits: [
            'Formulated with ultra-pure Lactic Acid to gently exfoliate dead skin cells to reveal smoother, more radiant skin',
            'Supports collagen production, targeting uneven skin texture and visibly minimizes lines, wrinkles and large pores',
            'Hydrators maintain moisture levels and prevent excess dryness',
            'Skin appears firmer and brighter, with renewed texture, tone and radiance',
            'Used with Glycolic Acid, Lactic Acid Resurfacing Serum can help boost exfoliation.',
        ],
        link: 'https://divinebeautyformula.myshopify.com/collections/all/products/divine-radiance-lactic-acid-resurfacing',
        ingredients: [
            '15% Niacinamide',
            'Algae Extract',
            'Green Tea Polyphenols & Licorice Root Extract',
            'Alpha Arbutin & Ascorbyl Glucoside',
            'Allantoin',
            'Peptide Complex',
        ],
    },
    {
        name: 'Divine Vibration Rich Restoration Moisturizer',
        price: '$117',
        size: '57g',
        description:
            'This moisture-boosting cream, fortified with ceramides, peptides, antioxidants and hydrators, nourishes dry skin back to optimum health, supports barrier repair and lipid replenishment.',
        benefits: [
            'Delivers nourishment to help repair compromised skin barrier',
            'Locks in moisture, helping to prevent TEWL',
            'Visibly improves skin elasticity and texture',
            'Supports the skin matrix to target fine lines and wrinkles',
            'Guards skin against free radicals that cause skin aging',
        ],
        link: 'https://divinebeautyformula.myshopify.com/collections/all/products/divine-vibration-rich-restoration-moisturizer',
        ingredients: [
            'Ceramides',
            'Green Tea Polyphenols',
            'Resveratrol',
            'Caffeine USP',
            'Stem Cells (Apple)',
            'Tetrapeptide-21',
            'Hyaluronic Acid',
        ],
    },
    {
        name: 'Divine Sunrise Tinted Mineral Matte SPF 50',
        price: '$52',
        size: '54g',
        description:
            'This all-mineral formula delivers broad-spectrum UVA/UVB SPF 50 sun protection. It combines an enriched lipid replenishment system for all-day oil-control, gentle hydration, and antioxidant age-defying benefits. A light-reflecting universal tint blends with most skin tones to blur imperfections and minimize the appearance of melasma, fine lines, wrinkles, and pores.',
        benefits: [
            'Finely milled Zinc Oxide provides superior mineral protection from harmful UVA/UVB rays',
            'Helps balance skin’s moisture levels and oil production',
            'Powerhouse antioxidants neutralize and quench UV-induced free radicals to calm skin',
            'Universal Pigment Technology reflects light and helps minimize the appearance of imperfections',
            'May be worn alone or as a primer under makeup',
        ],
        link: 'https://divinebeautyformula.myshopify.com/collections/all/products/divine-sunrise-tinted-mineral-matte-spf-50',
        ingredients: [
            'Zinc Oxide 20.5%',
            'Ceramide NG and Phospholipids',
            'Green Tea Polyphenols',
            'Centella Asiatica',
            'Resveratrol',
            'Biotin',
            'Co-Q10',
            'Emblica',
            'Vitamin E and Ascorbic Acid (Vitamin C)',
            'Iron Oxide and Titanium Dioxide',
        ],
    },
    {
        name: 'Divine Sunlight Sheer Tinted Mineral Matte SPF 50',
        price: '$44',
        size: '53g',
        description:
            'This mineral-based, oil-free formula delivers broad-spectrum UVA/UVB SPF 50 sun protection for acne and rosacea prone skin types. Lactic and hyaluronic acids are gentle and keep skin hydrated, calm and promote a more even tone. Niacinamide helps visibly brighten areas of discoloration and blemishes. Lightweight, soothing and perfect for everyday use.',
        benefits: [
            'Mineral-based broad-spectrum UVA/UVB protection',
            'Hyaluronic Acid boosts hydration, helping to diminish visible fine lines',
            'Antioxidant infused to soothe and calm skin',
            'Soft, fast-absorbing, residue free application ',
        ],
        link: 'https://divinebeautyformula.myshopify.com/collections/all/products/divine-sunlight-sheer-tinted-mineral-matte-spf-50',
        ingredients: [
            'Zinc Oxide 14.5%',
            'Octinoxate 7.5%',
            'Niacinamide',
            'Lactic Acid',
            'Hyaluronic Acid',
        ],
    },
    {
        name: 'Divine Sunlight Sheer Lightweight Mineral Matte SPF 50',
        price: '$44',
        size: '63g',
        description:
            'This mineral-based, oil-free formula delivers broad-spectrum UVA/UVB SPF 50 sun protection for acne and rosacea prone skin types. Lactic and hyaluronic acids are gentle and keep skin hydrated, calm and promote a more even tone. Niacinamide helps visibly brighten areas of discoloration and blemishes. Lightweight, soothing and perfect for everyday use.',
        benefits: [
            'Mineral-based broad-spectrum UVA/UVB protection',
            'Hyaluronic Acid boosts hydration, helping to diminish visible fine lines',
            'Antioxidant infused to soothe and calm skin',
            'Soft, fast-absorbing, residue free application ',
        ],
        link: 'https://divinebeautyformula.myshopify.com/collections/all/products/divine-sunlight-sheer-lightweight-mineral-matte-spf-50',
        ingredients: [
            'Zinc Oxide 14.5%',
            'Octinoxate 7.5%',
            'Niacinamide',
            'Lactic Acid',
            'Hyaluronic Acid',
        ],
    },
    {
        name: 'Divine Moment Papaya Magic Sensitive Cleanser',
        price: '$45',
        size: '200ml',
        description:
            'Papain enzymes from papaya fruit gently exfoliate debris and dead skin cells. Enriched with antioxidant Green Tea and Vitamin E to calm and enhance the skin barrier.',
        benefits: [
            'Papaya enzymes help to soften the skin Promotes a clear complexion',
            'Safe on sensitive and acne prone skin',
            'Green tea and vitamin E calm and enhance the skin barrier',
        ],
        link: 'https://divinebeautyformula.myshopify.com/collections/all/products/divine-moment-papaya-magic-sensitive-cleanser',
        ingredients: ['Papain Enzymes', 'Green Tea Extract', 'Vitamin E'],
    },

    {
        name: 'Divine Fairy Dust Mineral Powder SPF 30',
        price: '$54',
        size: '5g',
        description:
            'A 100% mineral powder in a lightweight formula that provides full facial sun protection with an easy to apply brush application',
        benefits: [
            'Ceramides protect against visible signs of aging',
            'Evenly distributed broad spectrum UVA/UVB protection',
            'Blurs imperfections and reduces shine',
            'Soothes visible redness & potential irritation',
            'Reduces oil & impurities on skin’s surface',
            'Won’t disturb makeup',
        ],
        link: 'https://divinebeautyformula.myshopify.com/collections/all/products/divine-fairy-dust-mineral-powder-spf-30',
        ingredients: [
            '24.1% Micronized Zinc Oxide',
            'Ceramide EOP',
            'Sodium Hylauronate (Hyaluronic Acid)',
            'Green Tea & Ascorbic Aci',
        ],
    },

    {
        name: 'Divine Intervention Skin Filter Effect',
        price: '$145',
        size: '30g',
        description:
            'Formulated with an exclusive biomimetic decoy molecule that enhances skin’s ability to prevent and correct visible discoloration and maintain even skin tone.',
        benefits: [
            'Visibly reduces dark spots',
            'Helps prevent future discoloration',
            'Promotes a brighter, more even skin tone',
            'Supports healthy collagen',
        ],
        link: 'https://divinebeautyformula.myshopify.com/collections/all/products/divine-intervention-skin-filter-effect',
        ingredients: ['ProteXidine™', 'Hexylresorcinol', 'Arbutin', 'Hexanoyl Dipeptide-3'],
    },

    {
        name: 'Divine Intervention Skin Filter Effect Men’s Care',
        price: '$145',
        size: '30g',
        description:
            'Formulated with an exclusive biomimetic decoy molecule that enhances skin’s ability to prevent and correct visible discoloration and maintain even skin tone.',
        benefits: [
            'Visibly reduces dark spots',
            'Helps prevent future discoloration',
            'Promotes a brighter, more even skin tone',
            'Supports healthy collagen',
        ],
        link: 'https://divinebeautyformula.myshopify.com/collections/all/products/divine-intervention-skin-filter-effect-men-s-care',
        ingredients: ['ProteXidine™', 'Hexylresorcinol', 'Arbutin', 'Hexanoyl Dipeptide-3'],
    },
    {
        name: 'Divine You Under-Eye Concealer and Perfector',
        price: '$78',
        size: '8.5g',
        description:
            'Broad spectrum SPF 50+ mineral eye treatment offers multi-benefit protection and correction against the visible signs of aging. Improve the look of dark circles, puffiness, sagging skin, and fine lines around the delicate eye area immediately and over time. May be applied as a concealer or concealing primer.',
        benefits: [
            'Delivers SPF 50+ mineral broad-spectrum',
            'UVA/UVB protection',
            'Universal tint corrects visible discoloration dark circles and minor imperfections',
            'Addresses sagging and fine lines',
            'Cooling applicator plus antioxidants help depuff and soothe',
            'Prevents moisture loss around the eye area',
        ],
        link: 'https://divinebeautyformula.myshopify.com/collections/all/products/divine-you-under-eye-concealer-and-perfector',
        ingredients: [
            'Zinc Oxide 6.5%',
            'Titanium Dioxide 6.4%',
            'Universal Pigment',
            'Peptide Blend',
            'Edelweiss Flower Extract',
            'Kelp Extract',
            'Hydrolyzed Vegetable Protein',
            'Hyaluronic Acid ',
        ],
    },
];
